import MicroModal from 'micromodal';
import { DotLottie } from '@lottiefiles/dotlottie-web';
import { tns } from "tiny-slider/src/tiny-slider"
export default {
  init() {
    // JavaScript to be fired on the our values page
    if($(".values-explore")[0]){``
      MicroModal.init();
      const slider = tns({
        container: '.upper-slider',
        items: 1,
        slideBy: 1,
        autoplay: false,
        controls: false,
        nav: false
      });
      $(".upper-modals__prev").click(function(e){
        e.preventDefault()
          slider.goTo('prev');
      });
      $(".upper-modals__next").click(function(e){
        e.preventDefault()
        slider.goTo('next');
      });
      $(".values-modal-full__close").click(function(e){
        e.preventDefault();
        $(".values-modal-full").removeClass("is-open");
        $("html").removeClass('lock-scroll');
      });
      const slideAnimation = (info, eventName) => {
          let slide = $(".tns-slide-active").find(".lottie-player");
          new DotLottie({
            autoplay: true,
            canvas: slide.get(0),
            src: slide.data('src'), // or .json file
          });
      };
      slider.events.on('indexChanged',slideAnimation)
      $("#trigger-inclusive").click(function(e){
        e.stopPropagation();
        slider.goTo(0);
        $(".values-modal-full").addClass("is-open");
        $("html").addClass('lock-scroll');
      });
      $("#trigger-passion").click(function(e){
        e.preventDefault();
        slider.goTo(1);
        $(".values-modal-full").addClass("is-open");
        $("html").addClass('lock-scroll');
      });
      $("#trigger-love").click(function(e){
        e.preventDefault();
        slider.goTo(2);
        $(".values-modal-full").addClass("is-open");
        $("html").addClass('lock-scroll');
      });
      $("#trigger-right").click(function(e){
        e.preventDefault();
        slider.goTo(3);
        $(".values-modal-full").addClass("is-open");
        $("html").addClass('lock-scroll');
      });
      $("#trigger-trust").click(function(e){
        e.preventDefault();
        slider.goTo(4);
        $(".values-modal-full").addClass("is-open");
        $("html").addClass('lock-scroll');
      });
      $("#trigger-together").click(function(e){
        e.preventDefault();
        slider.goTo(5);
        $(".values-modal-full").addClass("is-open");
        $("html").addClass('lock-scroll');
      });
      // observer
        const observer = new IntersectionObserver(function (entries) {
          entries.forEach((entry) => {
            if(entry.isIntersecting) {
              circleAnimation()
            }
          });
        });
        observer.observe(document.querySelector('.values-explore__picker'));
        const circleAnimation = () => {
          if(!$('.values-explore__picker').hasClass('skip')) {
            setTimeout(() => {
              $("#trigger-together").removeClass('state-hidden').addClass('state-active');
            }, 500);
            setTimeout(() => {
              $("#trigger-passion").removeClass('state-hidden').addClass('state-active');
            }, 1500);
            setTimeout(() => {
              $("#trigger-trust").removeClass('state-hidden').addClass('state-active');
            }, 2500);
            setTimeout(() => {
              $("#trigger-love").removeClass('state-hidden').addClass('state-active');
            }, 3500);
            setTimeout(() => {
              $("#trigger-right").removeClass('state-hidden').addClass('state-active');
            }, 4500);
            setTimeout(() => {
              $("#trigger-inclusive").removeClass('state-hidden').addClass('state-active');
            }, 5500);
            setTimeout(() => {
              $(".help-text").removeClass('hidden');
              $('.values-explore__picker').addClass('skip');
              $('.values-explore__circle-item').removeClass('state-active');
            }, 6500);
          }
        };
    }
  },
  finalize() {
    // JavaScript to be fired on the our values page, after the init JS
  },
};
