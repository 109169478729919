import MicroModal from 'micromodal';
import _, { debounce } from 'underscore';
import { tns } from "tiny-slider/src/tiny-slider"
export default {
  init() {
    // JavaScript to be fired on the anniversary page
    if($(".anniversary")[0]){
      let sliders = [];
      $(".anniversary__period").each(function () {
          let containerWidth = $(this).find(".container").width();
          $(this).find('.item--size-super').width(containerWidth);
          let autoWidth = $(window).width() > 900;
          let id = $(this).attr('id');
          let containerElm = ".timeline-" + id;
          let prevElm = ".prev-" + id;
          let nextElm = ".next-" + id;
          if (!autoWidth) {
            prevElm += '-mobile';
            nextElm += '-mobile';
          }
          const slider = tns({
            autoWidth: autoWidth,
            loop: false,
            container: containerElm,
            nextButton: nextElm,
            prevButton: prevElm,
            gutter: 20,
            items: 1,
            slideBy: 1,
            autoplay: false,
            nav: false,
            controls: true,
            mouseDrag: false,
            lazyload: true,
          });
          sliders.push(slider);
      });
      $( window ).on( "resize", _.debounce(function() {
        // kill 'em all
        //console.log(sliders);

        $.each(sliders,function(idx,val){
          //console.log(val);
          if(val !== undefined){
            val.destroy();
          }
        });
        // reset keeper array
        sliders = [];
        $(".anniversary__period").each(function() {
          let containerWidth = $(this).find(".container").width();
          $(this).find('.item--size-super').width(containerWidth);
          let autoWidth = $(window).width() > 900;
          let id = $(this).attr('id');
          let containerElm = ".timeline-"+id;
          let prevElm = ".prev-"+id;
          let nextElm = ".next-"+id;
          if(!autoWidth){
            prevElm += '-mobile';
            nextElm += '-mobile';
          }
          const slider = tns({
            autoWidth: autoWidth,
            loop: false,
            container: containerElm,
            nextButton: nextElm,
            prevButton: prevElm,
            gutter: 20,
            items: 1,
            slideBy: 1,
            autoplay: false,
            nav: false,
            controls: true,
            mouseDrag: false,
            lazyload: true,
          });
          sliders.push(slider);
        });
      },500));
      //console.log('init anniversary');
      var fixedTop = $('.fixed-on-scroll').offset().top;       // get initial position of the element
      $(window).scroll(function() {
        let offset = ($(window).width() < 900) ? 0 : 236;// assign scroll event listener
        var currentScroll = $(window).scrollTop() + offset; // get current position
        //console.log(currentScroll);
        if (currentScroll >= fixedTop) {           // apply position: fixed if you
          //console.log('set to fixed')
          $('.fixed-on-scroll').css({                      // scroll to that element or below it
            position: 'fixed',
            top: offset+'px',
            left: '0',
            width: '100vw',
            'z-index': 100
          });
          $('.anniversary__subhero').css({
            'margin-bottom':'87px'
          });
        } else {                                   // apply position: static
          //console.log('reset');
          $('.fixed-on-scroll').css({                      // if you scroll above it
            position: 'static'
          });
          $('.anniversary__subhero').css({
            'margin-bottom':'0'
          });
        }
      });
      MicroModal.init();

      $("body").on("click", ".item__media", function(e) {
        //console.log("click img modal");
          e.preventDefault();
          $(this).find('figure').clone().appendTo("#load-image");
          MicroModal.show('image-modal',{
            onShow: function(modal){
              $("html").addClass('lock-scroll');
            },
            onClose: function(modal){
              $("html").removeClass('lock-scroll');
              $("#load-image").empty();
            },
            disableScroll: false,
            disableFocus: true,
          });
      });
      $("body").on("click", ".read-more-modal-btn", function(e) {
        //console.log("click read modal");
        e.preventDefault();
        $(this).next().clone().appendTo("#load-text");
        MicroModal.show('text-modal',{
          onShow: function(modal){
            $("html").addClass('lock-scroll');
          },
          onClose: function(modal){
            $("html").removeClass('lock-scroll');
            $("#load-text").empty();
          },
          disableScroll: false,
          disableFocus: true,
        });
      });

      var observer = new IntersectionObserver(function(entries, observer) {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            //do something
            //console.log(entry.target.id);
            $('#'+entry.target.id+'-target').removeClass('inactive');
          } else {
            $('#'+entry.target.id+'-target').addClass('inactive');
          }
        });
      }, {
        rootMargin: '0px',
        threshold: 0.5
      });
      document.querySelectorAll('.anniversary__period').forEach((i) => {
        if (i) {
          observer.observe(i);
        }
      });
    }
  },
  finalize() {
    // JavaScript to be fired on the our values page, after the init JS
  },
};


